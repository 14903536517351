<template>
    <ul>
        <li><router-link :to="{name: 'Home'}">{{ $t("Dashboard") }}</router-link></li>
        <li><a :class="['has-sub-menu', showUsers ? 'router-link-active' : '']" @click="showUsers ? showUsers = false : showUsers = true">{{ $t("Users") }}</a></li>
        <ul v-if="showUsers">
            <li v-for="userGroup in userGroups" :key="userGroup.id">
                <router-link :to="{ name: 'Users', params: { group: userGroup.name}}"><small>{{ userGroup.name }}</small></router-link>
            </li>
        </ul>
        <li><a :class="['has-sub-menu', showUsersGroups ? 'router-link-active' : '']" @click="showUsersGroups ? showUsersGroups = false : showUsersGroups = true">{{ $t("User groups") }}</a></li>
        <ul v-if="showUsersGroups">
            <li v-for="userGroup in userGroups" :key="userGroup.id">
                <router-link :to="{ name: 'UserGroups', params: { group: userGroup.name}}"><small>{{ userGroup.name }}</small></router-link>
            </li>
        </ul>
        <li>
            <router-link :to="{ name: 'Industries' }">{{ $t("Industries") }}</router-link>
        </li>
        <li>
            <router-link :to="{ name: 'Events' }">{{ $t("Events") }}</router-link>
        </li>
        <li>
            <router-link :to="{ name: 'Notifications' }">{{$t("Notifications")}}</router-link>
        </li>
        <li>
            <router-link :to="{ name: 'Workflows' }">{{$t("Workflows") }}</router-link>
        </li>
        <li>
            <router-link :to="{ name: 'GeneralSettings' }">{{$t("General settings") }}</router-link>
        </li>
        <li><a :class="['has-sub-menu', showCustomFields ? 'router-link-active' : '']" @click="showCustomFields ? showCustomFields = false : showCustomFields = true">{{ $t("Custom fields") }}</a></li>
        <ul v-if="showCustomFields">
            <li v-for="customFieldType in customFieldTypes" :key="customFieldType.id">
                <router-link :to="{ name: 'CustomFields', query: { type: customFieldType }}"><small>{{ customFieldType }}</small></router-link>
            </li>
        </ul>
    </ul>
</template>

<script>

export default {
    name: "ClientSettingsPanel",
    data() {
        return {

            showUsers: false,
            showUsersGroups: false,
            showCustomFields: false,
        }
    },
    computed: {
        customFieldTypes() {
            return this.$store.getters.customFieldTypes
        },
        userGroups() {
            return this.$store.getters.userGroups
        },
    }
}
</script>